<template>
  <div class="sidebar">
    <!-- Sidebar header -->
    <div class="sidebar-header">
      <a href="/home" class="logo-auth">
        <img src="/images/logo.jpg" alt="Logo" id="main-logo" />
        AVOAMERICA
      </a>
      <a
        @click="mtd_sidebar"
        class="nav-link nav-icon rounded-circle ml-auto"
        data-toggle="sidebar"
      >
        X
      </a>
    </div>
    <!-- /Sidebar header -->

    <!-- Sidebar body -->
    <simplebar class="sidebar-body" id="sidebar-menu">
      <ul class="metismenu list-unstyled">
        <li class="nav-label">MENU</li>
        <template v-for="(item, key) in Menu">
          <!-- Single Menu -->
          <router-link
            :key="key"
            tag="li"
            :to="item.path"
            active-class="item-active"
            v-if="
              !item.heading && !item.submenu && can.includes(item.permission)
            "
          >
            <a
              @click="toggleItemCollapse('')"
              class="nav-link has-icon"
              :title="item.name"
            >
              <i :class="item.icon"></i>
              <span class="ml-1">{{ item.name }}</span>
            </a>
          </router-link>

          <!-- Menu With Subitems -->
          <li
            class="nav-item"
            v-if="
              !item.heading && item.submenu && mtd_showSection(item.permissions)
            "
            :key="key"
          >
            <a
              class="nav-link has-icon treeview-toggle has-arrow"
              :class="[
                collapse[item.name] ? 'show' : '',
                routeActiveClass(getSubRoutes(item)),
              ]"
              :title="item.name"
              @click="toggleItemCollapse(item.name)"
            >
              <i :class="item.icon"></i>
              <span class="ml-1">{{ item.name }}</span>
            </a>

            <ul class="nav sub-menu">
              <template v-for="(sitem, key) in item.submenu">
                <router-link
                  tag="li"
                  class="nav-item"
                  :key="key"
                  :to="sitem.path"
                  active-class="nav-active"
                  v-if="can.includes(sitem.permission)"
                >
                  <a class="nav-link" :title="sitem.name">{{ sitem.name }}</a>
                </router-link>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </simplebar>
    <!-- /Sidebar body -->
  </div>
  <!-- /Sidebar -->
</template>
<script>
/** Vuex */
import { mapGetters, mapActions } from "vuex";
/** Components */
import simplebar from "simplebar-vue";
import Menu from "../../config/menu.js";
export default {
  components: {
    simplebar,
  },
  data() {
    return {
      Menu,
      collapse: this.buildCollapseList(),
    };
  },
  computed: {
    ...mapGetters(["authentication__user", "can"]),
    cp_state_toggle: function () {
      return this.$store.getters["state_toggler"];
    },
  },
  methods: {
    ...mapActions(["execute_commit"]),
    mtd_sidebar: function () {
      this.execute_commit({
        mutation: "AUTH__STATE_TOGGLE",
        payload: {},
      });
    },
    isRouteActive(paths) {
      paths = Array.isArray(paths) ? paths : [paths];
      return paths.indexOf(this.$route.path.replace("/", "/")) > -1;
    },
    buildCollapseList() {
      let collapse = {};
      Menu.filter(({ heading }) => !heading).forEach(
        ({ name, path, submenu }) => {
          collapse[name] = this.isRouteActive(
            submenu ? submenu.map(({ path }) => path) : path
          );
        }
      );
      return collapse;
    },
    mtd_showSection: function (permissions) {
      let rpta = false;
      permissions.forEach((permission, index) => {
        if (this.can.includes(permission)) rpta = true;
      });
      return rpta;
    },
    routeActiveClass(paths) {
      return { active: this.isRouteActive(paths) };
    },
    getSubRoutes(item) {
      return item.submenu.map(({ path }) => path);
    },
    toggleItemCollapse: function (collapseName) {
      let actual = this.collapse[collapseName];
      for (let c in this.collapse) {
        this.collapse[c] = false;
      }
      this.collapse[collapseName] = !actual;
    },
  },
};
</script>
