let layouts = {
    'ADMINISTRADOR': {
        template: 'DefaultLayout'
    },
    'MEDICO': {
        template: 'MedicoLayout'
    },
    'DEFAULT': {
        template: 'DefaultLayout'
    },
}

let get_layout_auth = (roles) => {
    if (roles.length > 1) return layouts['DEFAULT']
    else return layouts[roles[0]] ? layouts[roles[0]] : layouts['DEFAULT']
}

export default get_layout_auth