<template>
  <component :is="template" />
</template>
<script>
import get_layout_auth from "../config/layout";
import { mapActions, mapGetters } from "vuex";
import Loading from "../components/shared/loading/Modal.vue";
import DefaultLayout from "../layouts/auth/Default";
export default {
  components: {
    Loading,
    DefaultLayout,
  },
  data() {
    return {
      template: "Loading",
    };
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/");
    } else {
      this.eventBus();
      this.checkAuth();
    }
  },
  computed: {
    ...mapGetters(["authentication__user", "url_api"]),
  },
  created() {},
  methods: {
    ...mapActions(["get","execute_commit"]),
    eventBus: function () {
      window.eventBus = this.$bus;
    },
    checkAuth: function () {
      this.get({
        url: this.url_api + "/users/check-auth",
        authorization: true,
        token: localStorage.getItem("token"),
      })
        .then((response) => {
          this.template = get_layout_auth(
            this.authentication__user.roles
          ).template;
          this.execute_commit({
            mutation: "AUTH__USER_SAVE",
            payload: {
              user: response.data,
            },
          });
          localStorage.setItem("permissions", response.data.permissions);
          document.cookie = `permissions=${response.data.permissions};`;
        })
        .catch((error) => {
          document.cookie = "permissions=";
          localStorage.setItem("permissions", "");
          localStorage.setItem("token", "");
          this.$router.push("/");
        });
    },
  },
};
</script>