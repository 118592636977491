<template>
  <div class="main-header">
    <a
      style="cursor: pointer"
      class="nav-link nav-link-faded rounded-circle nav-icon"
      @click="mtd_sidebar"
      data-toggle="sidebar"
      ><i class="material-icons">menu</i></a
    >
    <div class="ml-auto">
      <span class="separator"></span>
      <div id="userbox" class="userbox">
        <a href="#" data-toggle="dropdown">
          <figure class="profile-picture">
            <img
              :src="authentication__user.photo"
              alt="Joseph Doe"
              class="rounded-circle"
            />
          </figure>
          <div class="profile-info">
            <span class="name">{{ authentication__user.first_name }}</span>
            <span class="role">{{ authentication__user.roles[0] }}</span>
          </div>

          <i class="fa custom-caret"></i>
        </a>

        <div class="dropdown-menu">
          <ul class="list-unstyled mb-2">
            <li class="divider"></li>
            <li>
              <a role="menuitem" tabindex="-1" @click="mtd_salir"
                ><i class="ri-shut-down-line"></i> Salir</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/** Vuex */
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["authentication__user", "url_api"]),
  },
  methods: {
    ...mapActions(["execute_commit", "get"]),
    mtd_salir: function () {
      this.get({
        url: this.url_api + "/users/logout",
        authorization: true,
        token: localStorage.getItem("token"),
      })
        .then((response) => {
          document.cookie = "permissions=";
          this.$router.push("/");
          localStorage.clear();
        })
        .catch((error) => {});
    },
    mtd_sidebar: function () {
      this.execute_commit({
        mutation: "AUTH__STATE_TOGGLE",
        payload: {},
      });
    },
  },
};
</script>