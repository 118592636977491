<template>
  <section
    id="layout__auth-default"
    class="body"
    :class="cp_state_toggle ? 'sidebar-collapse sidebar-expand' : ''"
  >
    <sidebar></sidebar>
    <div class="main">
      <!-- Main header -->
      <main-header></main-header>
      <!--app content-->
      <router-view></router-view>
      <!--end app content-->
    </div>
    <!--app loading-->
    <loading-modal v-show="loading" />
    <!--end app loading-->
    <div
      @click="mtd_sidebar"
      class="sidebar-backdrop"
      id="sidebarBackdrop"
      data-toggle="sidebar"
    ></div>
  </section>
</template>
<script>
/** Vuex */
import { mapGetters, mapActions } from "vuex";
/** Components */
import LoadingModal from "../../components/shared/loading/Modal";
import Sidebar from "../../components/auth/sidebar";
import MainHeader from "../../components/auth/main_header";
export default {
  components: {
    LoadingModal,
    Sidebar,
    MainHeader,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.onEventBusLoading();
  },
  computed: {
    cp_state_toggle: function () {
      return this.$store.getters["state_toggler"];
    },
  },
  beforeDestroy() {
    window.eventBus.$off(
      "loading",
      function () {
        this.loading = false;
      }.bind(this)
    );
  },
  methods: {
    ...mapActions(["execute_commit"]),
    onEventBusLoading: function () {
      window.eventBus.$on(
        "loading",
        function (state) {
          this.loading = state;
        }.bind(this)
      );
    },
    mtd_sidebar: function () {
      this.execute_commit({
        mutation: "AUTH__STATE_TOGGLE",
        payload: {},
      });
    },
  },
};
</script>