const Menu = [
  {
    heading: "MENU",
  },
  {
    name: "Escritorio",
    path: "/home",
    icon: "ri-home-8-line",
    permission: "home-index",
  },
  {
    name: "Inventario",
    icon: "ri-file-copy-2-line",
    permissions: ["inventory-store"],
    submenu: [
      {
        name: "Ingresar",
        path: "/inventory-store",
        permission: "inventory-store",
      },
      {
        name: "Listar",
        path: "/inventory-index",
        permission: "inventory-index",
      },
    ],
  },
  {
    name: "Usuarios",
    icon: "ri-user-line",
    permissions: ["users-store"],
    submenu: [
      {
        name: "Registrar",
        path: "/users-store",
        permission: "users-store",
      },
      {
        name: "Listar",
        path: "/users-index",
        permission: "users-index",
      },
    ],
  },
  {
    name: "Reportes",
    path: "/reports",
    icon: "ri-bar-chart-grouped-line",
    permission: "home-index",
  },
  /** CAMBIAR CONTRASEÑA */
  {
    name: "Cambiar contraseña",
    path: "/change-password",
    icon: "ri-lock-line",
    permission: "home-index",
  },
];

export default Menu;
